<template>
  <div class="layout-container">
    <el-container>
      <!-- 左侧侧边 200px => 64px -->
      <el-aside :width="isCollapse ? '64px' : '200px'">
        <!-- 头部logo -->
        <div class="logo" :class="{ minLogo: isCollapse }"></div>
        <el-menu
          :default-active="$route.path"
          router
          :collapse-transition="false"
          :collapse="isCollapse"
          :unique-opened="true"
          background-color="#002033"
          text-color="#fff"
          active-text-color="#ffd04b"
        >
          <el-menu-item index="/">
            <i class="el-icon-s-home"></i>
            <span slot="title">首页</span>
          </el-menu-item>
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span slot="title">影视导航</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/study/movies1">主流平台</el-menu-item>
              <el-menu-item index="/study/movies2">我爱追剧</el-menu-item>
              <el-menu-item index="/study/movies3">视频教程</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-document"></i>
              <span slot="title">生活百科</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/playfan/playfan1">发现知道</el-menu-item>
              <el-menu-item index="/playfan/playfan2">新闻资讯</el-menu-item>
              <el-menu-item index="/playfan/playfan3">电商平台</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-share"></i>
              <span slot="title">软件资源</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/softwareResources/findSoftware"
                >发现软件</el-menu-item
              >
              <el-menu-item index="/softwareResources/resourceSearch"
                >资源搜索</el-menu-item
              >
              <el-menu-item index="/softwareResources/onlineDoc"
                >网盘文档邮箱</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">
              <i class="el-icon-s-opportunity"></i>
              <span slot="title">设计导航</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/designNav/designTool"
                >设计工具</el-menu-item
              >
              <el-menu-item index="/designNav/designResources"
                >素材资源</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="5">
            <template slot="title">
              <i class="el-icon-s-custom"></i>
              <span slot="title">俺是程序员</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/programmer/frontEnd">前端开发</el-menu-item>
              <el-menu-item index="/programmer/backEnd">后端开发</el-menu-item>
              <el-menu-item index="/programmer/blog">博客文档</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="6">
            <template slot="title">
              <i class="el-icon-s-custom"></i>
              <span slot="title">军校导航</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/junxiao/pc">PC</el-menu-item>
              <el-menu-item index="/junxiao/mobile">移动端</el-menu-item>
              <el-menu-item index="/junxiao/knowledge">军校周边</el-menu-item>
              <el-menu-item index="/junxiao/work">我的工作</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="/editWebsite" v-if="isCanEdit">
            <i class="el-icon-s-home"></i>
            <span slot="title">网站编辑</span>
          </el-menu-item>
          <div class="side_isCollapse" @click="toggleMenu" title="点我切换">
            <i :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
          </div>
        </el-menu>
      </el-aside>
      <!-- 右边 -->
      <el-container>
        <el-main>
          <!-- 供切换的二级路由的出口 -->
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'Layout',
  data() {
    return {
      isCollapse: false,
      isCanEdit: false
    }
  },
  mounted() {
    if (this._isMobile()) {
      this.isCollapse = true
    } else {
      this.isCollapse = false
    }
    this.$EventBus.$on('switchChanged', msg => {
      console.log('XXXXXX--loncon_看看改成什么了', msg)
      this.isCanEdit = msg
    })
  },

  methods: {
    toggleMenu() {
      this.isCollapse = !this.isCollapse
    },
    _isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    }
  }
}
</script>

<style lang="less" scoped>
.layout-container {
  position: fixed;
  width: 100%;
  height: 100%;
  .el-container {
    width: 100%;
    height: 100%;
  }
  .el-aside {
    background-color: #002033;
    scrollbar-width: none; // firefox下滚动条不显示
    -ms-overflow-style: none; // IE下滚动条不显示
    //设置侧边栏滚动条不显示 chrome
    &::-webkit-scrollbar {
      display: none;
    }
    .logo {
      width: 100%;
      height: 100px;
      background: url(~@/assets/logo.png) no-repeat center;
      background-size: 100px auto;
    }
    // 覆盖background 的图片和大小，类书写在logo的下方。
    .minLogo {
      background-image: url(~@/assets/imgUrl.png);
      background-size: 36px auto;
    }
    .el-menu {
      border-right: none;
    }
    .side_isCollapse {
      color: #fff;
      position: absolute;
      left: 30px;
      bottom: -110px;
    }
  }
  .el-main {
    background: url('~@/assets/home.jpg') fixed 0 / cover;
    div {
      padding: 30px;
    }
  }
}
</style>
